/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background-color: #eee;

    // background: $primary-color;
    @media (min-width: 1200px) {
        .container {
            max-width: 750px;
        }
    }

    @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        display: block;
        margin: 0 auto;

        >.row {
            min-height: 100vh;

            >.aut-bg-img {
                min-height: 100vh;
            }
        }
    }

    .saprator {
        position: relative;
        margin: 8px 0;

        span {
            background: $body-bg;
            position: relative;
            padding: 0 10px;
            z-index: 5;
            font-size: 20px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $theme-border;
            z-index: 1;
        }
    }

    a,
    p>a {
        color: $theme-heading-color;
        font-weight: 600;
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        padding: 8px;

        .card-body {
            padding: 20px 25px 20px 40px;
        }

        @media (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: block;
        }
    }

    .uat-card {
        margin-bottom: 0;
        padding: 8px;


        .card-body {
            padding: 20px 25px 20px 40px;
        }

        @media (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: block;
        }
    }

    >div {
        z-index: 5;
    }

    .bg-wave {
        width: 100%;
        position: absolute;
        bottom: 0;
        opacity: 0.5;
    }

    .auth-content {
        position: relative;
        padding: 15px;
        z-index: 5;

        &:not(.container) {
            width: 750px;

            .card-body {
                padding: 40px 35px;
            }
        }

        @media (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            margin: 0 auto;
        }
    }

    .auth-side-img {
        padding-right: 400px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;

        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .auth-side-form {
        position: relative;
        min-height: 100%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        left: auto;
        float: right;

        >* {
            position: relative;
            z-index: 5;
        }

        @media only screen and (max-width: 991px) {
            width: 100%;
            justify-content: center;

            .auth-content:not(.container) {
                max-width: 350px;
            }
        }
    }

    &.aut-bg-img {
        .auth-side-form {

            @media (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                height: 100vh;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }

    &.offline {
        background-image: none;

        h1 {
            font-size: 87px;
            font-weight: 700;
        }

        &:before {
            display: none;
        }
    }

    .card-body .carousel-indicators {
        margin: 15px 0 10px;
        bottom: 0;

        li {
            width: 50px;
            background-color: transparentize($primary-color, 0.6);
            border-radius: 5px;
            height: 4px;

            &.active {
                background-color: $primary-color;
            }
        }
    }

    .img-logo-overlay {
        position: absolute;
        top: 40px;
        left: 50px;
    }
}

/* image varient start */
.aut-bg-img {
    background-image: url("../../../images/auth/img-auth-big.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .auth-content {
        padding: 70px 40px;
    }
}

/* image varient End */
/**====== Authentication css end ======**/

.img-bg {
    background-color: #244380;
    border-radius: 10px;
    color: white;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
}

.uat-img-bg {
    background-color: darkgreen;
    
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
}
.actionBlock {
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
    padding: 15px 20px;
}
.actionBlock  .haedingTxt {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 24px;
}
.actionBlock  .haedingTxt,
.auth-wrapper p > a,
.actionBlock .terms-block p {
    color: #797979;
}
.p-lr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.terms-block p {
    color:#fff;
    padding-left: 18px;
  }
  .uat-img-bg p {
    color: #fff;
    margin-bottom: 4px;
  }
  .modal-header.header-blue {
    background: #244380;
    color: rgba(255, 255, 255, 0.8);
  }
  .modal-terms-block .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 20px 25px;
    overflow-y: scroll;
    max-height: 500px;
  }
  .modal-terms-block .modal-header {
    padding: 10px 15px;
    display: block;
  }
  .modal-terms-block .logo-block img {
    max-width: 90px;
  }
  .modal-terms-block .nameTxt-block p {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
  }
  .modal-terms-block .nameTxt-block {
    width: 205px;
    float: left;
    display: block;
    padding: 0 15px;
  }
  .modal-terms-block .logo-block {
    float: left;
  }
  .modal-terms-block .title-Block {
    display: inline-block;
    width: 34%;
    float:left;
    text-align: center;
    padding: 15px 0;
    color: #fff;
  }
  .modal-terms-block .align-this{
    float:right;
    text-align:right;
    padding: 0;
  }
  .modal-terms-block .modal-body ul {
    padding-left: 15px;
  }
  .input-item {
    border: 1px solid #e0e0e0;
    border-bottom: 0;
  }
  .btn-primary {
    color: #fff;
    background-color: #244380;
    border-color: #244380;
}
.btn-primary.disabled, 
.btn-primary:disabled {
    color: #fff;
    background-color: #244380;
    border-color: #244380;
}
.chicklink {
    color: #315aaa !important;
    font-weight: 400 !important;
    cursor: pointer;
  }
  .chicklink:hover {
    text-decoration: underline;
  }
  
  .actionBlock,
  .uat-img-bg {
    box-shadow: 2px 9px 30px -15px rgba(0,0,0,0.8);
    -webkit-box-shadow: 2px 9px 30px -15px rgba(0,0,0,0.8);
    -moz-box-shadow: 2px 9px 30px -15px rgba(0,0,0,0.8);
  }