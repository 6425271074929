.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 70px);
}

.card {
  .card-header+.collapse {
    >.card-body {
      padding-top: 0;
    }
  }
}

.pcoded-navbar {
  .pcoded-inner-navbar {
    li {
      >a.active {
        color: #e5efff;
        /*font-weight: 600;*/
      }


      .pcoded-submenu>li {

        &.active,
        &:focus,
        &:hover {

          >a,
          >a.active {
            &:before {
              background: #e5efff;
            }
          }
        }

        >a.active {
          &:before {
            background: #e5efff;
          }
        }
      }
    }

    >li {

      &.active,
      &.pcoded-trigger {

        >a,
        >a.active {
          color: #e5efff;
        }
      }

    }

    >.pcoded-menu-caption {

      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }

  &.menu-light {
    .pcoded-inner-navbar {
      li {
        >a.active {
          color: $primary-color;
          background: transparent;

          &:before {
            background: $primary-color;
          }
        }

        .pcoded-submenu>li {

          >a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

      }
    }
  }
}

.search-bar {
  display: block;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 16px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: feather !important;
  content: "\e82a";
}

table.dataTable thead .sorting_asc:after {
  content: "\e82d";
}

table.dataTable thead .sorting_desc:after {
  content: "\e82a";
}

.css-1bm1z97-ToastContainer {
  top: 70px !important;

  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: transparent;
  text-shadow: none;
}

/* Custom styling */
.app-main-title-container {
  flex-direction: column;
}

.app-main-title {
  color: #e5efff;
  font-weight: bold;
  font-size: 25px;
}

.app-main-subtitle {
  color: #e5efff;
  font-weight: 400;
  font-size: 16px;
  margin-top: -20px;
}

.signin-app-main-title {
  color: #e5efff;
  font-weight: bold;
  font-size: 16px;
}

.signin-app-main-subtitle {
  color: #e5efff;
  font-weight: 400;
  font-size: 12px;
  margin-top: -20px;
}

.screen-header-container {
  background-color: $dark;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-container-variant1 {
  background-color: $primary-color-variant1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-container-variant2 {
  background-color: $primary-color-variant2;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-container-variant3 {
  background-color: $primary-color-variant3;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-container-variant4 {
  background-color: $primary-color-variant4;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-container-variant6 {
  background-color: $primary-color-variant6;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.screen-header-title {
  color: #e5efff;
  font-weight: bold;
}

.screen-header-subtitle {
  color: #e5efff;
  font-weight: 600;
}

.logo-main {
  height: 70px;
  margin-top: 5px;
}

.logo-main-minified {
  height: 100%;
  width: 90%;
  margin-top: 5px;
}

.nav-item-hidden {
  display: none;
}

.error-validation-input {
  color: $danger-color
}

.error-validation-totals {
  color: $danger-color;
  font-size: medium;
}

.section-heading {
  margin-bottom: 10px;
}

.buttons-bottom-form {
  margin-top: 50px;
}

.buttons-bottom-form-thin {
  margin-top: 20px;
}

.card-background {
  background-color: $card-background;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.buttons-bottom-inner-form {
  margin-top: 30px;
}

.buttons-middle-form{
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-search-heading {
  color: $dark;
  font-weight: 400;
  font-size: 16px;
}

.table-header-bg {
  background-color: "#3a6bcc";
  color: "#FFFFFF";
}

// .alert-wrapper.sc-ion-alert-md {
//   min-width: fit-content;
// }

// .alert-wrapper {
//   max-width: unset !important;
//   width: 100% !important;
// }

// .custome-class {
//   min-width: fit-content;
// }

.input-item {
  border: 1px solid #e0e0e0;
  border-bottom: 0;
}

.btn-refresh {
  margin-bottom: 15px;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgb(212, 160, 173) !important;
}